import {
    AfterViewInit,
    Component,
    HostListener,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {HttpClient} from '@angular/common/http';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AnimationOptions} from 'ngx-lottie';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AngularFireAnalytics} from '@angular/fire/analytics';
import {Location} from '@angular/common';
import {CarouselConfig} from 'ngx-bootstrap/carousel';
import {AngularFirestore} from '@angular/fire/firestore';
import {shareReplay} from 'rxjs/operators';
import {TipDJModalComponent} from '../modal/tipDJModal/TipDJModal.component';
import {EventModel} from '../../models/EventModel';
import {SongModel} from '../../models/SongModel';
import {SpotifyService} from '../../services/SpotifyService';
import {AuthService} from '../../auth/AuthService';
import {AppService} from '../../services/app.service';
import {Constants} from '../../helpers/Constants';
import {Helpers} from '../../helpers/Helpers';
import {PlaylistModel} from '../../models/PlaylistModel';
import {SpotifyTrackModel} from '../../models/SpotifyTrackModel';
import {HelpModalComponent} from '../modal/helpModal/helpModal.component';
import {WebService} from '../../webServices/WebService';
import {PartyPremiumRequestModalComponent} from '../modal/partyPremiumRequestModal/partyPremiumRequestModal.component';
import {UpvoteModalComponent} from '../modal/upvoteModal/upvoteModal.component';
import {interval} from 'rxjs';
import {GoogleComponent} from '../base/google.component';
import {IntroModalComponent} from '../modal/introModal/introModal.component';
import {FirstUseModalComponent} from '../modal/firstUseModal/firstUseModal.component';
import {QrCodeModalComponent} from '../modal/qrCodeModal/qrCodeModal.component';

declare var $: any;
declare var window: any;

@Component({
    templateUrl: 'party.component.html',
    providers: [
        {provide: CarouselConfig, useValue: {interval: 5000, noPause: false, showIndicators: true}}
    ]
})
export class PartyComponent
    extends GoogleComponent
    implements OnInit, AfterViewInit {

    @ViewChild('usernameModal') public usernameModal: ModalDirective;
    @ViewChild('checkingRequestModal') public checkingRequestModal: ModalDirective;
    @ViewChild('standardRequestModal') public standardRequestModal: ModalDirective;
    @ViewChild('askNumberModal') public askNumberModal: ModalDirective;
    @ViewChild('menuModal') public menuModal: ModalDirective;
    @ViewChild('expiredModal') public expiredModal: ModalDirective;
    @ViewChild(IntroModalComponent) public introModalComponent: IntroModalComponent;
    @ViewChild(FirstUseModalComponent) public firstUseModalComponent: FirstUseModalComponent;
    @ViewChild('currentPlaylistModal') public currentPlaylistModal: ModalDirective;
    @ViewChild(QrCodeModalComponent) public qrCodeModalComponent: QrCodeModalComponent;
    @ViewChild(UpvoteModalComponent) upvoteModalComponent: UpvoteModalComponent;
    @ViewChild(TipDJModalComponent) tipDJModalComponent: TipDJModalComponent;
    @ViewChild(HelpModalComponent) helpModalComponent: HelpModalComponent;
    @ViewChild(PartyPremiumRequestModalComponent) partyPremiumRequestModalComponent: PartyPremiumRequestModalComponent;

    @Input() showSpinner = true;

    uuid: string;
    party: string;
    partyName: string;

    key: string;
    searchSongs = [];
    event: EventModel;
    number: string;

    currentSongSelected: SongModel;
    currentSongThumb: string;
    currentSongTitle: string;
    currentSongKey: string;
    duration_ms: string;
    explicit: string;
    currentAppleMusic: string;
    currentSongArtist: string;
    currentRequestedBy: string;
    requestType = '1';

    findLocationTitle = 'Find Nearby DJ Session';
    findLocationDes =
        'Tell us your current location or a location near the session you’re looking for.';

    currentRequestedKey = '';

    noLocation = false;
    signature: string;
    events = [];

    djName: string;

    searchKeyword: string;
    shareLink: string;
    isRunning = false;
    progressValue = 3;
    phoneTypeFormGroup: FormGroup;
    @Input() phoneType: string;

    btnMenuClickedEvent: any;
    getSpotifyPlaylistEvent: any;

    playlistCountdown = 'The Next Playlist Starts In 20 mins 00 secs';
    playlistCountdownTimeOnly = ' 20 mins 00 secs';

    optionUpdatingPlaylist: AnimationOptions = {
        path: '/assets/updatingPlaylist.json'
    };
    optionCreateParty: AnimationOptions = {
        path: '/assets/createParty.json'
    };
    isUpdatingPlaylist = false;
    collectionSubscribe: any;

    constructor(
        public analytics: AngularFireAnalytics,
        public router: Router,
        public spotify: SpotifyService,
        public route: ActivatedRoute,
        public http: HttpClient,
        public webService: WebService,
        public toastr: ToastrService,
        public spinner: NgxSpinnerService,
        public authAppService: AuthService,
        public fb: FormBuilder,
        public appService: AppService,
        public location: Location,
        public firestore: AngularFirestore,
    ) {
        super(router, route, http, webService, toastr, spinner, authAppService,
            appService,
            location
        );

        this.registerEvent();

        this.checkLightDarkMode();

        this.handleRoute();
    }

    ngOnInit(): void {
        this.initForm();
        this.playlists = [];
        this.songs = [];
        this.events = [];
        this.shareLink = 'https://ujay.com' + this.router.url;

        this.disableBackButton();

        this.analytics.logEvent('Web_OpenPage');
    }

    ngAfterViewInit(): void {
        this.checkFirstTime();
        this.handleModal();
        interval(1000).subscribe(() => {
            this.updateClock();
        });
    }

    private disableBackButton(): void {
        window.history.pushState(null, null, null);
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                window.history.pushState(null, null, null);
            }
        });
    }

    async handleRoute() {
        this.route.queryParams.subscribe((params) => {
            this.key = params['key'];
            this.uuid = params['dj'];
            this.sessionType = 3;
            this.party = params['p'];
            if (this.party !== undefined) {
                localStorage.setItem(Constants.SETTINGS_PARTY_ID, this.party);
            } else {
                localStorage.setItem(Constants.SETTINGS_PARTY_ID, '');
            }

            this.number = params['n'];
            if (this.number !== undefined) {
                localStorage.setItem(Constants.SETTINGS_NUMBER, this.number);
            } else {
                this.number = localStorage.getItem(Constants.SETTINGS_NUMBER);
            }
        });
    }

    registerEvent() {
        this.btnMenuClickedEvent =
            this.appService.btnMenuClicked.subscribe((event) => {
                this.menuModal.show();
            });

        this.btnWrongVenueClickedEvent =
            this.appService.btnWrongVenueClicked.subscribe((event) => {
                this.btnWrongVenueClicked();
            });

        this.getSpotifyPlaylistEvent =
            this.appService.getSpotifyPlaylist.subscribe(async (event) => {
                await this.GetSpotifyPlaylistAndGoTo();
            });

        window.addEventListener('message', async (event) => {
            if (event.data.toString().includes('mins')) {
                this.playlistCountdown = 'The Next Playlist Starts In ' + event.data;
                this.playlistCountdownTimeOnly = event.data;
            }
            if (event.data.toString().includes('REFRESH_TICKER')) {
                console.log('REFRESH_TICKER');
                this.isUpdatingPlaylist = true;
            }
        });
    }

    checkLightDarkMode() {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            document.body.classList.toggle('dark-theme');
        }

        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
            const newColorScheme = event.matches ? 'dark' : 'light';
            if (newColorScheme == 'dark') {
                document.body.classList.toggle('dark-theme');
            } else {
                document.body.classList.toggle('dark-theme');
            }
        });
    }

    async refreshPage() {
        if (this.collectionSubscribe) {
            this.collectionSubscribe.unsubscribe();
        }
        this.collectionSubscribe = this.firestore.collection(`tb_spotify_playlist`)
            .doc(this.spotifyPlaylistId)
            .snapshotChanges()
            .pipe(shareReplay())
            .subscribe(data => {
                let changedData = data as any;
                let payload = changedData.payload.data();
                if(payload) {
                    if (payload.time != this.lastUpdated) {
                        this.lastUpdated = payload.time;
                        this.isUpdatingPlaylist = false;
                        this.GetSpotifyPlaylist();
                        this.GetSongRequestByStandard(false);
                    }
                    const found = this.songs.find((obj) => {
                        return obj.key === payload.key;
                    });
                    if (!found) {
                        if (this.songs.length > 0) {
                            this.isEmptyPlaylist = false;
                        }
                    }
                }
            });
    }

    btnWrongVenueClicked() {
        this.addressName = '';
        this.getUserLocation();
    }

    getUserLocation() {
        if (navigator.geolocation) {
            this.getCurrentLocation();
        } else {
            this.handleLocationFailed();
        }
    }

    async handleLocationFailed() {
        this.lat = localStorage.getItem(Constants.SETTINGS_LAT);
        this.lng = localStorage.getItem(Constants.SETTINGS_LNG);
        this.loadGoogleScript();
        if (this.lat === undefined || this.lat === null) {
            if (this.key === undefined) {
                this.noLocation = true;
            }
            this.hideLoading();
            if (this.sessionType != 3) {
                this.askLocationModal.show();
            }
        } else {
            this.address = localStorage.getItem(Constants.SETTINGS_ADDRESS);
            this.appService.Venue = this.address;
            if (this.sessionType == 3) {
                await this.GetSongRequestByStandard(false);
                await this.GetSpotifyPlaylist();
            }
        }

        await this.analytics.logEvent('Web_LocationPermissionFailed');
    }

    async onLocationSuccess(position: any) {
        this.noLocation = false;
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        Helpers.saveLocation(this.lat, this.lng);
        this.loadGoogleScript();
        await this.convertLatLngToAddress();
        await this.GetSpotifyPlaylist();
        await this.analytics.logEvent('Web_LocationPermissionSuccess');
    }

    onLocationFailed() {
        this.hideLoading();
        this.loadGoogleScript();
        if (this.sessionType != 3) {
            this.askLocationModal.show();
        } else {
            this.locationGoogleModal.show();
        }
    }

    getCurrentLocation() {
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                await this.onLocationSuccess(position);
            },
            (error) => {
                this.onLocationFailed();
            }, {
                enableHighAccuracy: true,
                timeout: 90000,
                maximumAge: 0,
            }
        );
    }

    async createPartyContinueClicked() {
        let root = await this.webService.createAnAlias(this.createPartyKeyword, this.address, this.currentPlaceId, this.lat, this.lng);
        this.createPartyModal.hide();
        if (root.code === 0) {
            this.appService.Venue = this.createPartyKeyword;
            this.addressName = this.createPartyKeyword;
            Helpers.saveLocation(this.lat, this.lng);
            localStorage.setItem(Constants.SETTINGS_ADDRESS, this.address);
            localStorage.setItem(Constants.SETTINGS_PLACEID, this.currentPlaceId);
            if (this.sessionType == 3) {
                await this.GetSongRequestByStandard(false);
            }
            this.locationModalComponent.updateInfo(this.addressName, this.address);
            this.locationModalComponent.locationIcon = './assets/img/ic_sign_plus_circle.png';

            this.standardSessionComponent.addressName = this.addressName;
            this.standardSessionComponent.subscribeFirestore();
            await this.GetSpotifyPlaylist();
            $('body').removeClass('modal-open');
            this.locationModalComponent.thisModal.hide();
        }
    }

    createPartyBackClicked() {
        this.getUserLocation();
        this.createPartyModal.hide();
        this.locationModalComponent.thisModal.show();
    }

    async checkFirstTime() {
        if (this.sessionType == 3) {
            if (this.party != undefined) {
                await this.getPartyById(this.party);
            } else {
                const isFirstTime = localStorage.getItem(
                    Constants.SETTINGS_FIRST_TIME
                );
                if (isFirstTime == null) {
                    this.introModalComponent.thisModal.show();
                    localStorage.setItem(Constants.SETTINGS_FIRST_TIME, '1');
                } else {
                    if (Number(isFirstTime) <= 3) {
                        this.introModalComponent.thisModal.show();
                    } else {
                        this.getUserLocation();
                    }
                    localStorage.setItem(Constants.SETTINGS_FIRST_TIME, (Number(isFirstTime) + 1).toString());
                }
            }
        }
    }

    async getPartyById(id: string) {
        let root = await this.webService.GetPartyById(id);
        if (root != null && root.code == 0) {
            let enable = root.data.enable;
            if(enable == "1") {
                this.noLocation = false;
                this.lat = root.data.latitude;
                this.lng = root.data.longitude;
                this.currentPlaceId = root.data.placeId;
                this.partyName = root.data.eventName;
                this.appService.Venue = root.data.eventName;
                this.addressName = root.data.eventName;
                this.address = root.data.address;

                if (root.data.isCollectPhoneNumbers == undefined) {
                    this.isCollectPhoneNumbers_party = '2';
                } else {
                    this.isCollectPhoneNumbers_party = root.data.isCollectPhoneNumbers;
                }

                if (root.data.isOnlyPickSongPlaylists == undefined) {
                    this.isOnlyPickSongPlaylists_party = '2';
                } else {
                    this.isOnlyPickSongPlaylists_party = root.data.isOnlyPickSongPlaylists;
                }

                if (root.data.isFullControlPlaylists == undefined) {
                    this.isFullyControl_party = '2';
                } else {
                    this.isFullyControl_party = root.data.isFullControlPlaylists;
                }
                if(this.isFullyControl_party == '1' ||
                    this.isOnlyPickSongPlaylists_party == '1') {
                    this.isPremium = true;
                }

                Helpers.saveLocation(this.lat, this.lng);
                await this.GetUserParty(root.data.uid);
                this.loadGoogleScript();
                await this.GetSongRequestByStandard(false);
                await this.getPartyPlaylist(id);
            } else {
                this.expiredModal.show();
            }
        }
    }

    async GetUserParty(uid: string) {
        let root = await this.webService.GeUserParty(uid);
        if (root != null && root.code == 0) {
            this.tipLink = root.data.tipLink;
            this.socialLink = root.data.socialLink;
        }
    }

    async getPartyPlaylist(id: string) {
        let root = await this.webService.GetPartyPlaylist(id);
        if (root != null && root.code == 0) {
            this.isSearchSong = false;
            this.playlists = [];
            let i = 0;
            root.data.forEach((element) => {
                element.index = i;
                this.playlists.push(element);
                i++;
            });

            if (this.playlists.length > 0) {
                this.isPremium = true;                await this.GetPartyPlaylistDetail(id, this.playlists[0].key);
            }
            await this.GetSpotifyPlaylist();
        }
    }

    async GetPartyPlaylistDetail(id: string, playlistKey: string) {
        let root = await this.webService.GetPartyPlaylistDetail(id, playlistKey);
        if (root != null && root.code == 0) {
            this.breakSongs = [];
            root.data.forEach((element) => {
                this.breakSongs.push(element);
            });
        }
    }

    btnSharePartyClicked() {
        this.menuModal.hide();
        this.qrCodeModalComponent.thisModal.show();
    }

    PartyPremiumConfirmClicked($event: string) {
        const ua = navigator.userAgent.toLowerCase();
        let url: string;
        if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1) {
            url = `sms:+18727135442&body=Press send on this text to add your song to the ${this.partyName} playlist!`;
        } else {
            url = `sms:+18727135442?body=Press send on this text to add your song to the ${this.partyName} playlist!`;
        }
        window.location.href = url;
        this.partyPremiumRequestModalComponent.thisModal.hide();
    }

    private initForm() {
        this.phoneTypeFormGroup = this.fb.group({
            phoneType: [this.phoneType],
            phone: ['', [Validators.pattern('[A-Za-z0-9-_ ()]+')]],
        });
    }

    async btnUsernameSkipClicked() {
        this.usernameModal.hide();
        await this.handleCheckCountdown();
    }

    openSearchLocation() {
        if (this.sessionType != 3) {
            this.askLocationModal.show();
        }
    }

    updateClock() {
        let t = Date.parse(Date());
        let seconds = Math.floor((t / 1000) % 60);
        let minutes = Math.floor((t / 1000 / 60) % 60);

        let remainMinutes = 0;
        let remainSeconds = 59 - seconds;

        let min = localStorage.getItem(Constants.SETTINGS_SPOTIFY_PLAYLIST_MIN);
        this.startPlaylistMinutes = Number(min);

        if (this.startPlaylistMinutes == undefined) {
            this.startPlaylistMinutes = 0;
        }

        let realCurrentStartPlaylistMinutes1 = 0;
        let realCurrentStartPlaylistMinutes2 = 0;
        let realCurrentStartPlaylistMinutes3 = 0;
        if (this.startPlaylistMinutes == 0
            || this.startPlaylistMinutes == 20
            || this.startPlaylistMinutes == 40) {
            realCurrentStartPlaylistMinutes1 = 0;
            realCurrentStartPlaylistMinutes2 = 20;
            realCurrentStartPlaylistMinutes3 = 40;
        } else if (this.startPlaylistMinutes < 20) {
            realCurrentStartPlaylistMinutes2 = this.startPlaylistMinutes + 20;
            realCurrentStartPlaylistMinutes3 = this.startPlaylistMinutes + 40;
            realCurrentStartPlaylistMinutes1 = this.startPlaylistMinutes;
        } else if (this.startPlaylistMinutes < 40) {
            realCurrentStartPlaylistMinutes3 = this.startPlaylistMinutes + 20;
            realCurrentStartPlaylistMinutes1 = this.startPlaylistMinutes + 40 - 60;
            realCurrentStartPlaylistMinutes2 = this.startPlaylistMinutes;
        } else {
            realCurrentStartPlaylistMinutes2 = this.startPlaylistMinutes + 40 - 60;
            realCurrentStartPlaylistMinutes3 = this.startPlaylistMinutes;
            realCurrentStartPlaylistMinutes1 = this.startPlaylistMinutes + 20 - 60;
        }

        if (minutes < realCurrentStartPlaylistMinutes1) {
            remainMinutes = realCurrentStartPlaylistMinutes1 - (minutes + 1);
        } else if (minutes < realCurrentStartPlaylistMinutes2) {
            remainMinutes = realCurrentStartPlaylistMinutes2 - (minutes + 1);
        } else if (minutes < realCurrentStartPlaylistMinutes3) {
            remainMinutes = realCurrentStartPlaylistMinutes3 - (minutes + 1);
        } else {
            remainMinutes = realCurrentStartPlaylistMinutes1 + 60 - (minutes + 1);
        }

        if (remainMinutes <= 0 && remainSeconds <= 1) {
            if (this.songs && this.songs.length > 0) {
                window.postMessage(`REFRESH_TICKER`);
            }
        }
        window.postMessage(`${remainMinutes} mins ${remainSeconds} secs`);
    }

    async showUsernameModal() {
        this.requesterName = localStorage.getItem('username');
        if (
            this.requesterName === '' ||
            this.requesterName === null ||
            this.requesterName === 'null' ||
            this.requesterName === undefined
        ) {
            this.requesterName = '';
            this.usernameModal.show();
        } else {
            if (this.sessionType == 3) {
                await this.requestStandardSong(
                    this.requesterName,
                    '',
                    this.currentSongKey,
                    this.currentSongTitle,
                    this.currentSongArtist,
                    this.currentSongThumb,
                    this.duration_ms,
                    this.explicit,
                    '$0.00',
                    '0',
                    '',
                    this.requestType,
                    this.lat,
                    this.lng
                );
            } else {
                await this.handleCheckCountdown();
            }
        }
    }

    async searchSongKeywordChanged(q: string) {
        if (q === '') {
            this.isSearchSong = this.breakSongs.length <= 0;
            this.searchSongs = [];
            return;
        }
        this.isSearchSong = true;
        if (this.isOnlyPickSongPlaylists_party == '1') {
            await this.handleSearchInPlaylists(q);
        } else {
            let access_token = localStorage.getItem('SPOTIFY_TOKEN');
            if (access_token == null) {
                this.spotify.getSpotifyToken().subscribe((result) => {
                    access_token = result.access_token;
                    localStorage.setItem('SPOTIFY_TOKEN', result.access_token);
                    this.handleSearchSong(q, access_token);
                });
            } else {
                this.handleSearchSong(q, access_token);
            }
        }
    }

    async handleSearchInPlaylists(q: string) {
        this.searchSongs = [];
        var result = await this.webService.Search(q, this.party);
        if (result && result.data) {
            result.data.forEach((track) => {
                track.name = track.title;
                track.imageUrl = track.thumb;
                track.artistName = track.artist;
                this.searchSongs.push(track);
            });
        }
    }

    handleSearchSong(q: string, access_token: string) {
        this.spotify.searchSong(q, access_token).subscribe((result) => {
            this.searchSongs = [];
            result.tracks.items.forEach((track) => {
                if (track.popularity >= 0
                    && track.duration_ms >= 10000
                    && track.duration_ms <= 540000) {
                    let artistName = '';
                    let imageUrl = '';
                    if (track.album.images != null && track.album.images.length > 0) {
                        imageUrl = track.album.images[0].url;
                    }
                    track.artists.forEach((artist) => {
                        artistName += artist.name + ', ';
                    });

                    artistName = artistName.trim().slice(0, -1);
                    track.imageUrl = imageUrl;
                    track.artistName = artistName;
                    this.searchSongs.push(track);
                }
            });
        });
    }

    async requestStandardSong(
        firstName: string,
        lastName: string,
        songKey: string,
        songName: string,
        songArtist: string,
        songThumb: string,
        duration_ms: string,
        explicit: string,
        packageName: string,
        packageId: string,
        requestValue: string,
        requestType: string,
        latitude: string,
        longitude: string
    ) {
        if (!latitude) {
            latitude = '0';
        }
        if (!longitude) {
            longitude = '0';
        }

        if (!this.addressName) {
            this.addressName = '';
        }

        if (!this.currentPlaceId) {
            this.currentPlaceId = '';
        }

        const uuid = this.generateUUID();

        this.spinner.show('spinner2');
        this.standardRequestModal.show();

        const root = await this.webService.requestStandardSong(
            firstName, lastName, songKey,
            songName, songArtist, songThumb, duration_ms,
            explicit, packageName, packageId, requestValue,
            requestType, latitude, longitude, this.addressName,
            this.address, this.currentPlaceId, '0', uuid
        );
        this.standardRequestModal.hide();
        if (root.code == 0) {
            this.currentRequestedKey = root.data;
            await this.GetSongRequestByStandard(true);
            await this.GetSpotifyPlaylist();
            $('body').removeClass('modal-open');
        }

        await this.analytics.logEvent('Web_RequestSong');
    }

    handleModal() {
        this.searchSongModalComponent.thisModal.onShow.subscribe(() => {
            setTimeout(function () {
                $('.modal-backdrop')
                    .not('.modal-stack')
                    .css('z-index', 1040)
                    .addClass('modal-stack');
            }, 0);
        });

        this.checkingRequestModal.onShow.subscribe(() => {
            setTimeout(function () {
                $('.modal-backdrop')
                    .not('.modal-stack')
                    .css('z-index', 1042)
                    .addClass('modal-stack');
            }, 0);
        });
        this.standardRequestModal.onShow.subscribe(() => {
            setTimeout(function () {
                $('.modal-backdrop')
                    .not('.modal-stack')
                    .css('z-index', 1042)
                    .addClass('modal-stack');
            }, 0);
        });

        this.searchSongModalComponent.thisModal.onHide.subscribe(() => {
            this.searchKeyword = '';
            this.searchSongs = [];
        });

        this.checkingRequestModal.onHide.subscribe(() => {
            this.isRunning = false;
            this.progressValue = 3;
        });

        this.locationModalComponent.thisModal.onHide.subscribe(() => {
            this.events = [];
        });
    }

    loadExternalScript(scriptUrl: string) {
        return new Promise((resolve, reject) => {
            const scriptElement = document.createElement('script');
            scriptElement.src = scriptUrl;
            scriptElement.onload = resolve;
            document.body.appendChild(scriptElement);
        });
    }

    onRequesterNameChange(value: string): void {
        this.requesterName = value;
    }

    async btnUsernameDoneClicked() {
        if (this.requesterName.trim() !== '') {
            localStorage.setItem('username', this.requesterName.trim());
            this.usernameModal.hide();
            if (this.sessionType == 3) {
                await this.requestStandardSong(
                    this.requesterName,
                    '',
                    this.currentSongKey,
                    this.currentSongTitle,
                    this.currentSongArtist,
                    this.currentSongThumb,
                    this.duration_ms,
                    this.explicit,
                    '$0.00',
                    '0',
                    '',
                    this.requestType,
                    this.lat,
                    this.lng
                );
            } else {
                this.handleCheckCountdown();
            }
        } else {
            this.requesterName = '';
            this.toastr.error(
                'Error',
                'A name is required. You can make one up. Just remember it.'
            );
        }
    }

    async btnSearchSongClicked() {
        await this.analytics.logEvent('Web_Btn_Search');
        this.searchSongModalComponent.updateStaticModal(false);
        this.searchSongModalComponent.thisModal.show();

        if (this.breakSongs.length > 0) {
            this.isSearchSong = false;
        }
    }

    getDisplayName() {
        this.requesterName = localStorage.getItem('username');
        if (
            this.requesterName === null ||
            this.requesterName === undefined ||
            this.requesterName === ''
        ) {
            this.requesterName = this.authAppService.displayName;
        }
        if (
            this.requesterName === null ||
            this.requesterName === undefined ||
            this.requesterName === ''
        ) {
            this.requesterName = '';
        }
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
        this.playlists = [];
        this.songs = [];
        this.events = [];
    }

    onVenueSelect(item: EventModel): void {
        this.key = item.key;
        this.locationModalComponent.thisModal.hide();
        this.playlists = [];
        this.songs = [];
        this.locations = [];
        this.router.navigate([''], {queryParams: {key: item.key}});

        this.analytics.logEvent('Web_SelectVenue');
    }

    async LocationModalItemLocationClicked(item: any) {
        $('body').removeClass('modal-open');
        this.locationModalComponent.thisModal.hide();

        this.appService.Venue = item.displayName.text;
        this.addressName = item.displayName.text;
        this.address = item.formattedAddress;
        this.currentPlaceId = item.id;
        this.lat = item.location.latitude;
        this.lng = item.location.longitude;
        Helpers.saveLocation(this.lat, this.lng);
        localStorage.setItem(Constants.SETTINGS_ADDRESS, this.address);
        localStorage.setItem(Constants.SETTINGS_PLACEID, this.currentPlaceId);

        this.songs = [];
        this.breakSongs = [];
        this.standardSessionComponent.songs = [];
        if (item.isParty) {
            this.party = item.key;
            await this.getPartyById(item.key);
        } else {
            await this.GetSpotifyPlaylist();
        }

        if (this.sessionType == 3) {
            await this.GetSongRequestByStandard(false);
        }
        this.locationModalComponent.updateInfo(this.addressName, this.address);
        this.standardSessionComponent.addressName = this.addressName;
        this.standardSessionComponent.subscribeFirestore();

        await this.analytics.logEvent('Web_SelectLocation');
    }

    async LocationModalBtnClicked($event: string) {
        if (this.addressName == null || this.addressName == '') {
            this.locationModalComponent.thisModal.hide();
            this.createPartyModal.show();
        } else {
            Helpers.saveLocation(this.lat, this.lng);
            localStorage.setItem(Constants.SETTINGS_ADDRESS, this.address);
            localStorage.setItem(Constants.SETTINGS_PLACEID, this.currentPlaceId);
            await this.GetSongRequestByStandard(false);
            this.standardSessionComponent.subscribeFirestore();
            await this.GetSpotifyPlaylist();
            $('body').removeClass('modal-open');
            this.locationModalComponent.thisModal.hide();

            await this.analytics.logEvent('Web_SelectAutoLocation');
        }
    }

    btnPlaylistClicked() {
        this.currentPlaylistModal.show();
    }

    btnTipDjClicked() {
        window.open(this.tipLink, '_blank');
    }

    btnFollowDjClicked() {
        window.open(this.socialLink, '_blank');
    }

    btnSpotifyPlaylistClicked() {
        let playlistId = localStorage.getItem(Constants.SETTINGS_SPOTIFY_PLAYLISTID);
        let uris = [];
        this.songs.forEach(s => {
            uris.push(s.Uri);
        });
        this.appService.updateSpotifyPlaylist.next(uris.toString());

        if (!playlistId) {
            this.appService.getSpotifyPlaylist.next();
        }
        window.open('spotify:playlist:' + playlistId, '_blank');
    }

    btnSYBPlaylistClicked() {
        window.open('https://open.soundtrack.app/library', '_blank');
        this.analytics.logEvent('Web_SYBMenuButton').then(r => {});
    }

    btnSaveQrCodeClicked() {
        let link = document.createElement('a');
        link.download = 'QrCode';
        link.href = 'assets/img/ic_qrcode_2000.png';
        link.click();
        this.qrCodeModalComponent.thisModal.hide();
    }

    async btnShareQRClicked() {
        this.qrCodeModalComponent.thisModal.hide();

        const shareData = {
            title: 'UJay',
            text: 'Request Songs Anywhere',
            url: 'https://ujay.com',
        };

        try {
            await navigator.share(shareData);
        } catch (err) {

        }
    }

    async btnCloseQRClicked() {
        this.qrCodeModalComponent.thisModal.hide();
    }

    async onPlaylistSelect(item: PlaylistModel) {
        this.playlists.forEach(function (value: PlaylistModel) {
            $('#' + value.key).css('border', '4px solid #ffffff00');
        });

        $('#' + item.key).css('border', '4px solid #53B1C0');
        await this.GetPartyPlaylistDetail(this.party, item.key);
    }

    canRequestASong() {
        if (this.event.isKaraoke !== '1') {
            return true;
        }
        let latestRequest = localStorage.getItem(Constants.SETTINGS_LATEST_REQUEST);
        if (!latestRequest) {
            latestRequest = '0';
        }
        const now = Date.now();
        const limitTime = 3 * 60000;
        const timeFromLastRequest = now - Number(latestRequest);
        if (timeFromLastRequest > limitTime) {
            return true;
        }
        const timeLeft = limitTime - timeFromLastRequest;
        this.toastr.error('Make Another Request in ' + Helpers.msToTime(timeLeft));
        return false;
    }

    onSearchSongSuggestSelect(item: SongModel) {
        if (item.key == '') {
            return;
        }

        this.btnUpvoteClicked(item);
        this.searchSongModalComponent.thisModal.hide();
    }

    onSpotifySongSelect(item: SpotifyTrackModel) {
        if (this.sessionType == 1 || this.sessionType == 2) {
            if (!this.canRequestASong()) {
                return;
            }
        }

        let artists = '';
        item.artists.forEach((a) => {
            artists += a.name + ',';
        });
        if (artists !== '') {
            artists = artists.substring(0, artists.length - 1);
        }
        this.currentSongThumb = item.imageUrl;
        this.currentSongTitle = item.name;
        this.currentSongArtist = artists;
        this.currentSongKey = item.uri;
        this.duration_ms = item.duration_ms.toString();
        this.explicit = item.explicit ? '1' : '0';
        this.progressValue = 3;
        this.isRunning = true;
        this.searchKeyword = '';
        this.searchSongModalComponent.thisModal.hide();

        this.handlePremiumPartyRequest();
    }

    async onSongSelect(item: SongModel) {
        this.currentSongThumb = item.Thumb;
        this.currentSongTitle = item.Title;
        this.currentSongArtist = item.Artist;
        this.currentSongKey = item.Uri;
        if (item.Duration != undefined) {
            this.duration_ms = item.Duration.toString();
        }
        this.explicit = item.Explicit ? '1' : '0';
        this.progressValue = 3;
        this.isRunning = true;
        this.searchKeyword = '';
        this.searchSongModalComponent.thisModal.hide();

        await this.handlePremiumPartyRequest();
    }

    async handlePremiumPartyRequest() {
        if (this.showPremiumRequestModal()) {
            this.requesterName = localStorage.getItem('username');
            if (
                this.requesterName === '' ||
                this.requesterName === null ||
                this.requesterName === 'null' ||
                this.requesterName === undefined
            ) {
                this.requesterName = '';
                this.usernameModal.show();
            } else {
                localStorage.setItem(Constants.SETTINGS_PARTY_PREMIUM_REQUEST_MODAL, '2');
                localStorage.setItem(Constants.SETTINGS_PARTY_PREMIUM_REQUEST_MODAL_PARTY, this.party);
                await this.requestPremiumPartySong(
                    this.requesterName,
                    '',
                    this.currentSongKey,
                    this.currentSongTitle,
                    this.currentSongArtist,
                    this.currentSongThumb,
                    this.duration_ms,
                    this.explicit,
                    '$0.00',
                    '0',
                    '',
                    this.requestType,
                    this.lat,
                    this.lng
                );
            }
        } else {
            await this.showUsernameModal();
        }
    }

    showPremiumRequestModal() {
        if (this.isCollectPhoneNumbers_party == '2') {
            return false;
        }

        const premiumRequestModal = localStorage.getItem(Constants.SETTINGS_PARTY_PREMIUM_REQUEST_MODAL);
        const premiumRequestModalParty = localStorage.getItem(Constants.SETTINGS_PARTY_PREMIUM_REQUEST_MODAL_PARTY);

        if (premiumRequestModal == undefined || premiumRequestModalParty == undefined) {
            return true;
        }

        return !(premiumRequestModalParty == this.party && premiumRequestModal == '2');
    }

    async requestPremiumPartySong(
        firstName: string,
        lastName: string,
        songKey: string,
        songName: string,
        songArtist: string,
        songThumb: string,
        duration_ms: string,
        explicit: string,
        packageName: string,
        packageId: string,
        requestValue: string,
        requestType: string,
        latitude: string,
        longitude: string
    ) {
        if (!latitude) {
            latitude = '0';
        }
        if (!longitude) {
            longitude = '0';
        }
        if (!this.addressName) {
            this.addressName = '';
        }
        if (!this.currentPlaceId) {
            this.currentPlaceId = '';
        }
        const uuid = this.generateUUID();
        this.spinner.show('spinner2');
        this.partyPremiumRequestModalComponent.thisModal.show();
        const root = await this.webService.requestStandardSong(firstName, lastName, songKey,
            songName, songArtist, songThumb, duration_ms,
            explicit, packageName, packageId, requestValue,
            requestType, latitude, longitude, this.addressName,
            this.address, this.currentPlaceId, this.isPremium ? '1' : '0', uuid
        );

        if (root.code === 0) {
            this.currentRequestedKey = root.data;
            await this.GetSongRequestByStandard(true);
            await this.GetSpotifyPlaylist();
            $('body').removeClass('modal-open');
        }

        await this.analytics.logEvent('Web_RequestSong');
    }

    onStandardSongSelect(item: SongModel): void {
        if (item.key == '') {
            return;
        }

        this.currentSongSelected = item;
        this.currentSongThumb = item.Thumb;
        this.currentSongTitle = item.Title;
        this.currentSongArtist = item.Artist;
        this.currentSongKey = item.key;
        this.currentAppleMusic = item.AppleMusic;
        this.currentRequestedBy = 'Requested by ' + item.Requester;

        this.upvoteModalComponent.thisModal.show();
    }

    upvoteSongItemClicked(item: SongModel): void {
        if (item.key == '') {
            return;
        }

        this.btnUpvoteClicked(item);
    }

    downvoteSongItemClicked(item: SongModel): void {
        if (item.key == '') {
            return;
        }

        this.btnDownvoteClicked(item);
    }

    async handleCheckCountdown() {
        await this.requestStandardSong(
            this.requesterName,
            '',
            this.currentSongKey,
            this.currentSongTitle,
            this.currentSongArtist,
            this.currentSongThumb,
            this.duration_ms,
            this.explicit,
            '$0.00',
            '0',
            '',
            this.requestType,
            this.lat,
            this.lng
        );
        this.checkingRequestModal.show();
    }

    btnCancelRequestClicked() {
        this.isRunning = false;
        this.progressValue = 3;
        this.checkingRequestModal.hide();
    }

    btnCloseUpvoteClicked() {
        this.upvoteModalComponent.thisModal.hide();
    }

    btnHowItWorkClicked($event: string) {
        this.introModalComponent.thisModal.hide();
        this.helpModalComponent.thisModal.show();
    }

    btnAppStoreClicked() {
        window.open('https://apps.apple.com/us/app/ujay/id1447792017', '_blank');
    }

    btnHelpClicked() {
        this.menuModal.hide();
        this.helpModalComponent.thisModal.show();
    }

    btnHelpRequestClicked($event: string) {
        this.helpModalComponent.thisModal.hide();
        if (this.address) {
            this.searchSongModalComponent.updateStaticModal(false);
            this.searchSongModalComponent.thisModal.show();
        } else {
            this.getUserLocation();
        }
    }

    btnHelpRequestCloseClicked($event: string) {
        this.helpModalComponent.thisModal.hide();
        if (!this.address) {
            this.getUserLocation();
        }
    }

    btnIntroRequestClicked($event: string) {
        this.introModalComponent.thisModal.hide();
        if (this.address) {
            this.searchSongModalComponent.updateStaticModal(false);
            this.searchSongModalComponent.thisModal.show();
        } else {
            this.getUserLocation();
        }
    }

    btnContactUsClicked() {
        window.location.href = "mailto:info@ujay.com";
        // window.open('https://ujayapp.com/#contactus', '_blank');
        this.analytics.logEvent('Web_ContactUsClicked').then(r => {});
    }

    btnGetDJClicked() {
        window.open('https://apps.apple.com/us/app/ujay/id1447792017', '_blank');
        this.analytics.logEvent('Web_GetDJClicked').then(r => {});
    }

    btnDJResourcesClicked() {
        window.open('https://ujayapp.com', '_blank');
        this.analytics.logEvent('Web_DJResourcesClicked').then(r => {});
    }

    async btnUpvoteModalClicked($event: void) {
        await this.handleUpvote();
    }

    async btnDownvoteModalClicked($event: void) {
        await this.handleDownvote();
    }

    async btnUpvoteClicked(item: SongModel) {
        this.currentSongSelected = item;
        this.currentSongThumb = item.Thumb;
        this.currentSongTitle = item.Title;
        this.currentSongArtist = item.Artist;
        this.currentSongKey = item.key;
        this.currentAppleMusic = item.AppleMusic;
        this.currentRequestedBy = 'Requested by ' + item.Requester;

        await this.handleUpvote();
    }

    async btnDownvoteClicked(item: SongModel) {
        this.currentSongSelected = item;
        this.currentSongThumb = item.Thumb;
        this.currentSongTitle = item.Title;
        this.currentSongArtist = item.Artist;
        this.currentSongKey = item.key;
        this.currentAppleMusic = item.AppleMusic;
        this.currentRequestedBy = 'Requested by ' + item.Requester;

        await this.handleDownvote();
    }

    async handleUpvote() {
        this.showLoading();
        const uuid = this.generateUUID();
        let root = await this.webService.upvote(this.currentSongSelected.TrackId, uuid, this.currentPlaceId, this.lat, this.lng);
        this.hideLoading();
        if (root.code === 0) {
            // this.currentSongSelected.NoRequested++;
            this.currentSongSelected.Upvoted = true;
            this.currentSongSelected.Downvoted = false;
            // this.standardSessionComponent.rearrange();
            this.upvoteModalComponent.thisModal.hide();
        } else if (root.code == 3) {
            // this.currentSongSelected.NoRequested++;
            this.currentSongSelected.Upvoted = false;
            this.currentSongSelected.Downvoted = false;
            this.standardSessionComponent.rearrange();
            this.upvoteModalComponent.thisModal.hide();
        } else if (root.code == 1) {
            this.toastr.error('Error', 'Something went wrong');
        } else {
            this.toastr.info('Info', root.msg);
        }

        await this.analytics.logEvent('Web_UpvoteClicked');
    }

    async handleDownvote() {
        this.showLoading();
        const uuid = this.generateUUID();
        let root = await this.webService.downvote(this.currentSongSelected.TrackId, uuid, this.currentPlaceId, this.lat, this.lng);
        this.hideLoading();
        if (root.code === 0) {
            // this.currentSongSelected.NoRequested--;
            this.currentSongSelected.Upvoted = false;
            this.currentSongSelected.Downvoted = true;
            this.standardSessionComponent.rearrange();
            this.upvoteModalComponent.thisModal.hide();
        } else if (root.code == 3) {
            // this.currentSongSelected.NoRequested--;
            this.currentSongSelected.Upvoted = false;
            this.currentSongSelected.Downvoted = false;
            this.standardSessionComponent.rearrange();
            this.upvoteModalComponent.thisModal.hide();
        } else if (root.code == 1) {
            this.toastr.error('Error', 'Something went wrong');
        } else {
            this.toastr.info('Info', root.msg);
        }
        await this.analytics.logEvent('Web_DownvoteClicked');
    }

    btnSpotifyClicked($event: void) {
        if (this.currentSongSelected.Uri == undefined) {
            return;
        }
        window.open(this.currentSongSelected.Uri, '_blank');
    }

    btnAppleMusicClicked($event: void) {
        if (this.currentSongSelected == null) {
            return;
        }
        window.open(this.currentSongSelected.AppleMusic, '_blank');
    }

    async StandardSessionBtnClicked(event: string) {
        switch (event) {
            case 'btnSearchSongClicked':
                await this.btnSearchSongClicked();
                break;
        }
    }

    onRequestSongDonated(): void {

    }

    onUpvoteDonated(): void {

    }

    async googlePlaceSelected() {
        await this.GetSongRequestTicker();
    }

    btnGoHomeClicked() {
        this.expiredModal.hide();
        this.router.navigate([], {queryParams: {}}).then(r => location.reload());
    }
}
